<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :total-items="totalItems"
          :headers="headers"
          :items="villages"
          :total-pages.sync="totalPages"
          :filters="filters"
          title="Kelurahan/Desa"
          subtitle="Halaman untuk menambahkan kelurahan"
          add-btn="Tambah Data"
          :is-loading="isLoadingData"
          @add-item="showFormAdd"
          @delete-item="confirmDestroy"
          @edit-item="showFormEdit"
          @province-filtered="handleProvinceFilter"
          @regency-filtered="handleRegencyFilter"
          @district-filtered="handleDistrictFilter"
          @change-page="paginationHandler"
          @reset-filter="resetFilter"
          @live-search="searchHandler"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Kelurahan/Desa': 'Edit Kelurahan/Desa'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <div>
          <v-autocomplete
            v-model="village.province_uuid"
            :items="provinces"
            label="Provinsi"
            return-object
            item-text="name"
            item-value="uuid"
            dense
            :disabled="!provinces.length || dialog =='edit'"
            outlined
            @change="changeListRegencies()"
          >
          </v-autocomplete>
        </div>
        <div>
          <v-autocomplete
            v-if="dialog == 'add'"
            v-model="village.regencies_uuid"
            :items="regenciesByProvince"
            label="Kabupaten/Kota"
            return-object
            item-text="name"
            item-value="uuid"
            dense
            outlined
            :messages="isNullRegencies"
            :disabled="!regenciesByProvince.length"
            @change="changeListDistrict()"
          >
          </v-autocomplete>
          <v-autocomplete
            v-if="dialog == 'edit'"
            v-model="village.regencies_uuid"
            :items="regencies"
            label="Kabupaten/Kota"
            return-object
            item-text="name"
            item-value="uuid"
            dense
            outlined
            :messages="isNullRegencies"
            :disabled="dialog == 'edit'"
          >
          </v-autocomplete>
        </div>
        <div>
          <v-autocomplete
            v-if="dialog == 'add'"
            v-model="village.district_uuid"
            :items="districtsByRegencies"
            label="Kecamatan"
            return-object
            item-text="name"
            item-value="uuid"
            dense
            outlined
            :messages="isNullDistrict"
            :disabled="!districtsByRegencies.length"
          >
          </v-autocomplete>
          <v-autocomplete
            v-if="dialog == 'edit'"
            v-model="village.district_uuid"
            :items="districts"
            label="Kecamatan"
            return-object
            item-text="name"
            item-value="uuid"
            dense
            outlined
            :messages="isNullDistrict"
            :disabled="dialog =='edit'"
          >
          </v-autocomplete>
        </div>
        <div>
          <v-text-field
            v-model="village.name"
            label="Kelurahan/Desa"
            dense
            :disabled="!village.district_uuid"
            outlined
          ></v-text-field>
        </div>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Kelurahan/Desa"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import ConfirmDialog from '../components/ConfirmDialog.vue'
import DataTablePagination from '../components/DataTablePagination.vue'
import MainCard from '../components/MainCard.vue'
import ModalDialog from '../components/ModalDialog.vue'
import SkeletonLoaderTable from '../components/SkeletonLoaderTable.vue'
// eslint-disable-next-line object-curly-newline
import { mdiDeleteOutline, mdiMagnify, mdiPencilOutline, mdiPlus } from '@mdi/js'

export default {
  name: 'Village',
  components: {
    DataTablePagination,
    ModalDialog,
    SkeletonLoaderTable,
    MainCard,
    ConfirmDialog,
  },
  data() {
    return {
      attrs: {
        class: 'mb-6',
        boilerplate: false,
        elevation: 5,
        type: {},
      },
      service: 'village',
      isLoadingButton: false,
      isDisabledButton: false,
      isLoadingTable: true,
      isLoadingData: false,
      search: '',
      modalDialog: false,
      dialog: '',
      width: 600,
      confirmDialog: false,
      page: 1,
      totalPages: 0,
      totalItems: 0,
      itemsPerPage: 10,
      provinces: [],
      province: {},
      regencies: [],
      regency: {},
      regenciesByProvince: [],
      isNullRegencies: '',
      districts: [],
      districtsByRegencies: [],
      isNullDistrict: '',
      district: {},
      villages: [],
      village: {
        name: '',
        district_uuid: null,
      },
      headers: [
        { text: '#', value: 'index' },
        { text: 'Provinsi', value: 'province' },
        { text: 'Kabupaten/Kota', value: 'regencies' },
        { text: 'Kecamatan', value: 'district' },
        { text: 'Kelurahan/Desa', value: 'name' },
        { text: 'Aksi', value: 'actions' },
      ],
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiPlus,
      },
      filters: [],
      filterQuery: {
        province: '',
        regencies: '',
        district: '',
        villages: '',
      },
      formValid: false,
    }
  },
  watch: {
    village: {
      handler() {
        const valid = []
        const requiredField = ['name', 'district_uuid']
        Object.entries(this.village).forEach(([key, item]) => {
          if (requiredField.includes(key)) {
            if (requiredField.includes(key) && Object.keys(item).length !== 0) {
              valid.push(!!(item && Object.keys(item).length))
            } else {
              valid.push(!!(item && item.length))
            }
          }
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    page: {
      handler() {
        this.listVillage(this.filterQuery)
      },
    },
  },
  async mounted() {
    this.isLoadingTable = true
    await this.listProvince()
    await this.listRegency()
    await this.listDistrict()
    await this.listVillage()
    this.isLoadingTable = false
    this.insertFiltersOption()
  },
  methods: {
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    async listProvince() {
      await this.$services.ApiServices.list('province', { per_page: 'all' }).then(
        ({ data }) => {
          this.provinces = data.data
        },
        err => console.error(err),
      )
    },
    async listRegency() {
      await this.$services.ApiServices.list('regency', { per_page: 'all' }).then(
        ({ data }) => {
          this.regencies = data.data
        },
        err => console.error(err),
      )
    },
    async listDistrict() {
      await this.$services.ApiServices.list('district', { per_page: 'all' }).then(
        ({ data }) => {
          this.districts = data.data
        },
        err => console.error(err),
      )
    },
    async listVillage(params = this.filterQuery) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.service, { ...params, page: this.page }).then(
        ({ data }) => {
          this.villages = data.data.map((villages, index) => ({
            ...villages,
            index: index + 1,
          }))
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
        },
        err => console.error(err),
      )
      this.isLoadingData = false
    },
    resetForm() {
      this.village.name = ''
      this.village.province_uuid = null
      this.village.regencies_uuid = null
      this.village.district_uuid = null
      this.regenciesByProvince = []
      this.districtsByRegencies = []
    },

    showFormAdd() {
      this.dialog = 'add'
      this.resetForm()
      this.modalDialog = true
    },
    changeListRegencies() {
      this.regenciesByProvince = this.regencies.filter(item => item.province_uuid === this.village.province_uuid.uuid)
      if (this.regenciesByProvince.length < 1) {
        this.isNullRegencies = 'provinsi yang anda pilih belum memiliki kabupaten'
      }
    },
    changeListDistrict() {
      this.districtsByRegencies = this.districts.filter(
        item => item.regencies_uuid === this.village.regencies_uuid.uuid,
      )
      if (this.districtsByRegencies.length < 1) {
        this.isNullDistrict = 'kecamatan yang anda pilih belum memiliki kelurahan'
      }
    },
    async add() {
      this.isLoadingButton = true
      this.isDisabledButton = true
      await this.$services.ApiServices.add(this.service, {
        name: this.village.name,
        district_uuid: this.village.district_uuid.uuid,
      }).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.isDisabledButton = false
          this.modalDialog = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listVillage()
    },

    async showFormEdit(uuid) {
      this.dialog = 'edit'
      this.modalDialog = true
      await this.$services.ApiServices.get(this.service, uuid).then(
        ({ data }) => {
          this.village = data.data
          this.village.uuid = data.data.uuid
          this.village.province = {
            name: this.village.province,
            uuid: this.village.province_uuid,
          }
          this.village.regencies = {
            name: this.village.regencies,
            uuid: this.village.regencies_uuid,
          }
          this.village.district = {
            name: this.village.district,
            uuid: this.village.district_uuid,
          }
          this.editFormDialog = true
        },
        err => console.error(err),
      )
    },
    async update() {
      this.isLoadingButton = true
      this.isDisabledButton = true
      await this.$services.ApiServices.update(
        this.service,
        {
          name: this.village.name,
          district_uuid: this.village.district.uuid,
        },
        this.village.uuid,
      ).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.isDisabledButton = false
          this.modalDialog = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listVillage()
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.village.uuid = uuid
    },

    async destroy() {
      this.isLoadingButton = true
      this.isDisabledButton = true
      await this.$services.ApiServices.destroy(this.service, this.village.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.isDisabledButton = false
          this.confirmDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listVillage()
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
    insertFiltersOption() {
      const provinceFilters = []
      const regencyFilters = []
      const districtFilters = []
      this.provinces.forEach(item => {
        provinceFilters.push(item.name)
      })

      this.filters = [
        ...this.filters,
        {
          label: 'Province',
          emitName: 'province-filtered',
          data: provinceFilters,
        },
        {
          label: 'Regency',
          emitName: 'regency-filtered',
          data: regencyFilters,
        },
        {
          label: 'District',
          emitName: 'district-filtered',
          data: districtFilters,
        },
      ]
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    async handleProvinceFilter(event) {
      this.filterQuery.province = event
      this.filterQuery.regencies = ''
      this.filterQuery.district = ''
      const regencyFilters = []
      await this.$services.ApiServices.list('regency', { province: this.filterQuery.province }).then(
        ({ data }) => {
          data.data.forEach(item => {
            regencyFilters.push(item.name)
          })
          this.filters = this.filters.filter(item => item.label !== 'Regency' && item.label !== 'District')
          this.filters = [
            ...this.filters,
            {
              label: 'Regency',
              emitName: 'regency-filtered',
              data: regencyFilters,
            },
            {
              label: 'District',
              emitName: 'district-filtered',
              data: [],
            },
          ]
        },
        err => console.error(err),
      )
      this.listVillage()
    },
    async handleRegencyFilter(event) {
      this.filterQuery.regencies = event
      this.filterQuery.district = ''
      const distictFilters = []
      this.$services.ApiServices.list('district', { regencies: this.filterQuery.regencies }).then(
        ({ data }) => {
          data.data.forEach(item => {
            distictFilters.push(item.name)
          })
          this.filters = this.filters.filter(item => item.label !== 'District')
          this.filters = [
            ...this.filters,
            {
              label: 'District',
              emitName: 'district-filtered',
              data: distictFilters,
            },
          ]
        },
        err => console.error(err),
      )
      this.listVillage()
    },
    async handleDistrictFilter(event) {
      this.filterQuery.district = event
      this.isLoadingData = true
      await this.listVillage()
      this.isLoadingData = false
    },
    resetFilter() {
      this.filterQuery = {
        province: '',
        regency: '',
        district: '',
      }
      this.listVillage()
    },
    searchHandler(villages) {
      this.filterQuery.villages = villages
      this.page = 1
      this.listVillage(this.filterQuery)
    },
  },
}
</script>

<style>
</style>
