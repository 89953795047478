<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :search="search"
          :total-items="totalItems"
          :headers="headers"
          :items="regencies"
          :total-pages.sync="totalPages"
          :filters="filters"
          :is-loading="isLoadingData"
          title="Kabupaten"
          subtitle="Halaman untuk menambahkan data Kabupaten"
          add-btn="Tambah Data"
          @delete-item="confirmDestroy"
          @edit-item="showFormEdit"
          @add-item="showFormAdd"
          @province-filtered="handleProvinceFilter"
          @change-page="paginationHandler"
          @live-search="searchHandler"
          @reset-filter="resetFilter"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Kabupaten/Kota': 'Edit Kabupaten/Kota'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <div>
          <v-autocomplete
            v-model="regency.province_uuid"
            :items="provinces"
            label="Provinsi"
            item-text="name"
            item-value="uuid"
            dense
            clearable
            :disabled="dialog == 'edit'"
            outlined
            @change="check"
          >
          </v-autocomplete>
        </div>
        <div>
          <v-text-field
            v-model="regency.name"
            label="Kabupaten"
            dense
            outlined
            :disabled="!regency.province_uuid"
          ></v-text-field>
        </div>
        <div v-if="dialog == 'add'">
          <FileInput
            :value="regency.logo"
            label="Logo"
            outlined
            dense
            :disabled="!regency.name"
            :prependicon="icons.mdiImageMultipleOutline"
            @input="getFile"
          ></FileInput>
        </div>
        <div v-if="dialog =='edit'">
          <FileInput
            v-model="initialFile"
            :prependicon="icons.mdiImageMultipleOutline"
            label="Logo"
            outlined
            dense
            @input="getFile"
          >
          </FileInput>
        </div>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Kabupaten/Kota"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import ConfirmDialog from '../components/ConfirmDialog.vue'
import DataTablePagination from '../components/DataTablePagination.vue'
import FileInput from '../components/FileInput.vue'
import MainCard from '../components/MainCard.vue'
import ModalDialog from '../components/ModalDialog.vue'
import SkeletonLoaderTable from '../components/SkeletonLoaderTable.vue'
// eslint-disable-next-line object-curly-newline
import { mdiDeleteOutline, mdiImageMultipleOutline, mdiMagnify, mdiPencilOutline, mdiPlus } from '@mdi/js'

export default {
  name: 'Regency',
  components: {
    DataTablePagination,
    SkeletonLoaderTable,
    MainCard,
    ModalDialog,
    ConfirmDialog,
    FileInput,
  },
  data() {
    return {
      attrs: {
        class: 'mb-6',
        boilerplate: false,
        elevation: 5,
        type: {},
      },
      service: 'regency',
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      isDisabledButton: false,
      loading: true,
      search: '',
      dialog: null,
      modalDialog: false,
      confirmDialog: false,
      width: 600,
      page: 1,
      totalPages: 0,
      itemsPerPages: 10,
      totalItems: 0,
      provinces: [],
      province: {
        name: '',
      },
      regencies: [],
      regency: {
        name: '',
        province_uuid: null,
      },
      headers: [
        { text: '#', value: 'index' },
        { text: 'Provinsi', value: 'province' },
        { text: 'Kabupaten/Kota', value: 'name' },
        { text: 'Aksi ', value: 'actions' },
      ],
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiPlus,
        mdiImageMultipleOutline,
      },
      filters: [],
      filterQuery: {
        province: '',
        regencies: '',
      },
      formValid: false,
    }
  },
  computed: {
    initialFile: {
      get() {
        if (typeof this.regency.logo === 'string') {
          return new File([this.regency.logo], this.regency.logo, { type: 'text/plain' })
        }

        return this.regency.logo
      },
      set() {
        return this.regency.logo
      },
    },
  },
  watch: {
    regency: {
      handler() {
        const valid = []
        const requiredField = ['name', 'province_uuid']
        Object.entries(this.regency).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    page: {
      handler() {
        this.listRegency({ ...this.filterQuery })
      },
    },
  },
  async mounted() {
    this.isLoadingTable = true
    await this.listRegency()
    this.isLoadingTable = false
    await this.listProvince()
    this.insertFiltersOption()
  },
  methods: {
    check() {},
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    async listRegency(params = this.filterQuery) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.service, { ...params, page: this.page }).then(
        ({ data }) => {
          this.totalItems = data.meta.total
          this.regencies = data.data.map((regencies, index) => ({
            ...regencies,
            index: index + 1,
          }))
          this.totalPages = data.meta.last_page
        },
        err => console.error(err),
      )
      this.isLoadingData = false
    },
    async listProvince() {
      await this.$services.ApiServices.list('province', { per_page: 'all' }).then(
        ({ data }) => {
          this.totalProvinces = data.meta.total
          this.provinces = data.data
        },
        err => console.error(err),
      )
    },
    resetForm() {
      this.regency.province_uuid = ''
      this.regency.name = ''
      this.regency.logo = null
    },
    getFile(data) {
      this.regency.logo = data
    },
    showFormAdd() {
      this.dialog = 'add'
      this.resetForm()
      this.modalDialog = true
    },
    async add() {
      this.isLoadingButton = true
      this.isDisabledButton = true
      this.formData = new FormData()
      this.formData.append('province_uuid', this.regency.province_uuid)
      this.formData.append('name', this.regency.name)
      this.formData.append('logo', this.regency.logo)
      await this.$services.ApiServices.add(this.service, this.formData).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.isDisabledButton = false
          this.modalDialog = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listRegency()
    },

    async showFormEdit(uuid) {
      this.dialog = 'edit'
      this.modalDialog = true
      this.isDisabledButton = false
      await this.$services.ApiServices.get(this.service, uuid).then(
        ({ data }) => {
          this.regency = data.data
          this.regency.uuid = data.data.uuid
          this.regency.province = {
            name: this.regency.province,
            province_uuid: this.regency.province_uuid,
          }
        },
        err => console.error(err),
      )
    },
    async update() {
      this.isLoadingButton = true
      this.isDisabledButton = true
      this.formData = new FormData()
      this.formData.append('province_uuid', this.regency.province_uuid)
      this.formData.append('name', this.regency.name)
      if (typeof this.regency.logo === 'object') {
        this.formData.append('logo', this.regency.logo)
      } else {
        this.formData.delete('logo')
      }

      await this.$services.ApiServices.update(this.service, this.formData, this.regency.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.isDisabledButton = false
          this.modalDialog = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listRegency()
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.regency.uuid = uuid
    },

    async destroy() {
      this.isLoadingButton = true
      this.isDisabledButton = true
      await this.$services.ApiServices.destroy(this.service, this.regency.uuid).then(
        ({ data }) => {
          this.listRegency()
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.isDisabledButton = false
          this.confirmDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listRegency()
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },

    insertFiltersOption() {
      const provinceFilters = []
      this.provinces.forEach(item => {
        provinceFilters.push(item.name)
      })

      this.filters = [
        ...this.filters,
        {
          label: 'Province',
          emitName: 'province-filtered',
          data: provinceFilters,
        },
      ]
    },

    async handleProvinceFilter(event) {
      this.filterQuery.province = event
      await this.listRegency()
    },
    searchHandler(regencies) {
      this.filterQuery.regencies = regencies
      this.page = 1
      this.listRegency({ ...this.filterQuery })
    },
    resetFilter() {
      this.filterQuery = {
        province: '',
        regencies: '',
      }

      this.listRegency()
    },
  },
}
</script>

<style>
</style>
